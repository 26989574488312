<template>
  <div class="user-list">
    <div class="user-list__title-wrapper">
      <TitleBase>{{ titleSelectedUsers }}</TitleBase>
    </div>
    <TableList
      :isReady="isPageReady"
      :headers="headersSelected"
      :items="selectedUsers"
    >
      <template v-slot:item.user="{ item }">
        <tr>
          <td>
            <span @click="removeUser(item)">
              <Round :type="2">
                <v-icon dark>mdi-minus</v-icon>
              </Round>
            </span>
          </td>
        </tr>
      </template>
    </TableList>
    <div class="user-list__title-wrapper">
      <TitleBase>{{ titlePlatformUser }}</TitleBase>
    </div>
    <TableList
      :isReady="isPageReady"
      :headers="headers"
      :items="displayedUsers"
      :footer-props="footerProps"
      :ssp="true"
      :ssp-length="sspLength"
      :current-filters.sync="currentFilters"
      @update:ssp="updateSsp"
      ref="usersTable"
    >
      <template v-slot:item.user="{ item }">
        <tr>
          <td>
            <span @click="addUser(item)">
              <Round :type="2">
                <v-icon dark>mdi-plus</v-icon>
              </Round>
            </span>
          </td>
        </tr>
      </template>
    </TableList>
  </div>
</template>

<script>
import Component from "vue-class-component";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";
import Translations from "@/mixins/translations";
import TitleBase from "@/components/dom/TitleBase";

@Component({
  props:{
    selectedUsers: { default: []},
  },
  emits:[
    "addUser",
    "removeUser"
  ],
  components: {
    Round,
    TableList,
    TitleBase,
  },
  inject: {
    groupService: "groupService",
    toastService: "toastService",
    userService: "userService",
  },
})
export default class AssignedUsers extends Translations {
  isPageReady = false;
  groups = [];
  usersGroup = [];
  displayedUsers = [];
  currentFilters = {};
  sspLength = 0;
  footerProps = {
    itemsPerPageOptions: [5, 10, 15, 20],
  };

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get titleSelectedUsers() {
    return this.translations.labels.title_selected_users;
  }

  get titlePlatformUser() {
    return this.translations.labels.title_plat_user;
  }

  get headers() {
    return [
      {
        text: this.translations.labels.user_table_email,
        value: "email",
      },
      {
        text: this.translations.labels.user_table_lastName,
        value: "lastName",
      },
      {
        text: this.translations.labels.user_table_firstName,
        value: "firstName",
      },
      {
        text: this.translations.labels.user_table_username,
        value: "username",
      },
      {
        text: "Select",
        value: "user",
        type: "button",
        icon: "mdi-plus",
        buttonType: 2,
      },
    ];
  }

  get headersSelected() {
    return [
      {
        text: this.translations.labels.user_table_email,
        value: "email",
      },
      {
        text: "Deselect",
        value: "user",
        type: "button",
        icon: "mdi-minus",
        buttonType: 2,
      },
    ];
  }

  async removeUser(user) {
    if (confirm(this.translations.success.remove_user)){
      let indexToRemove = this.selectedUsers.findIndex(x => x.id == user.id);
      if (indexToRemove >= 0)
        this.selectedUsers.splice(indexToRemove, 1);

      this.$emit("removeUser", user);

      if (this.$refs.usersTable.tableOptions)
        await this.updateSsp(this.$refs.usersTable.currentFilters, this.$refs.usersTable.tableOptions);
    }
  }

  async addUser(user) {
    if (this.selectedUsers.find(x => x.id == user.id) != undefined)
        this.toastService.error(this.translations.errors.user_already_selected);
    else {
        this.selectedUsers.push(user);
    }

    this.$emit("addUser", user);

    if (this.$refs.usersTable.tableOptions)
      await this.updateSsp(this.$refs.usersTable.currentFilters, this.$refs.usersTable.tableOptions);
  }

  async updateSsp(filters, options) {
    const sspFilters = {
      ...filters,
      page: options.page,
      perPage: options.itemsPerPage,
      exclude: this.selectedUsers.map(x => x.id)
    };

    if ((sspFilters?.orderBy ?? "").startsWith("enable")) {
      sspFilters.orderBy = sspFilters.orderBy.replace("enable", "isEnabled");
    }
    const res = await this.userService.paginatedList(sspFilters);
    this.displayedUsers = res.result;
    this.sspLength = res.meta.totalItems;
  }

  async init() {

    try {
      this.displayedUsers  = await this.userService.list();
      this.groups = await this.groupService.list();
      this.usersGroup = this.groups.filter(
        (g) => g.name.toLowerCase() == "owner"
      );
      this.isPageReady = true;
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async created() {
    await this.init();
  }
}
</script>