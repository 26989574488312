<template>
  <div class="entity-form">
    <div class="entity-form__body" v-if="isReady">
      <FormBody
        :form-id="getFormId()"
        :form-title="getFormTitle()"
        :model.sync="model"
        :actions="actions"
        :elements="decoratedElements"
        :columns="fieldColumns"
        :errors.sync="errors"
        :helpLink="helpLink"
        v-on="actionsHandlers"
      >
        <slot></slot>
      </FormBody>
      <AssignedUsers
      v-if="model.assignmentPolicy == 'UsersAssigned'"
      :selectedUsers="selectedUsers"
      @removeUser="removeUser"
      @addUser="addUser"
      />
    </div>
  </div>
</template>

<script>
import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";
import WithVisitorLevelPermissions from "../../../../mixins/WithVisitorLevelPermissions";
import AssignedUsers from "./components/AssignedUsers.vue";
import Translations from "../../../../mixins/translations";

@Component({
  inject: {
    eventService: "eventService",
    categoryService: "categoryService",
    toastService: "toastService"
  },
  components:{
    AssignedUsers
  }
})
export default class DefaultSwagbagFileForm extends mixins(EntityForm, WithVisitorLevelPermissions, Translations) {
  model = {};
  platformLanguages = [];
  selectedUsers = [];
  categories = [];

  get platformLanguagesOptions() {
    return this.platformLanguages.map((l) => {
      return {
        value: l.id,
        label: l.description,
      };
    });
  }

  get categoriesOptions() {
    return this.categories.map((c) => {
      return {
        value: c.id,
        label: c.code,
      };
    });
  }

  get selectedLanguages() {
    const modelLanguages = this.model?.languageIds ?? [];
    return this.platformLanguages.filter((l) => {
      return modelLanguages.includes(l.id);
    });
  }

  get selectedLanguagesOptions() {
    const selectedLanguages = this.selectedLanguages.map((l) => {
      return {
        value: l.id,
        label: l.description,
      };
    });
    return selectedLanguages;
  }

  get elements() {
    return [
        {
            id: "idLanguage",
            label: this.translations.labels.languages,
            type: "select",
            items: this.platformLanguagesOptions,
            multiple: false,
            required: true,
        },
        {
            id: "idCategory",
            label: this.translations.labels.categories,
            type: "select",
            items: this.categoriesOptions,
            multiple: false,
            required: true,
        },
        {
            id: "name",
            label: this.translations.labels.swagbag_name,
            type: "text",
            required: true,
            rules: "min:4",
        },
        {
            id: "uri",
            label: this.translations.labels.swagbag_uri,
            type: "media",
            mediaType: "document",
            required: true,
        },
        {
            id: "description",
            label: this.translations.labels.swagbag_description,
            type: "text",
        },
        {
            id: "assignmentPolicy",
            label: this.translations.labels.swagbag_assignmentPolicy,
            required: true,
            type: "select",
            items: [
              {
                value: "AllUsers",
                label: "All Users"
              },
              {
                value: "UsersAssigned",
                label: "Assigned Users"
              },
              {
                value: "VisitorLevel",
                label: "Assigned Visitor Levels"
              }
            ],
        },
        ...Utility.addIf(
            {
                id: "visitorLevels",
                type: "visitor-levels-permissions",
                resetColumns: true,
                transcludeModel: true,
                legend: this.translations.labels.visitor_level,
                label: this.translations.labels.visitor_level,
                levels: this.currentVisitorLevelsOptions
            },
            this.model.assignmentPolicy == "VisitorLevel"
        ),
    ];
  }

  get helpLink() {
    return this.translations.pageHelp.swagbagFile;
  }

  removeUser(user){
    if (this.model.users){
      let indexToRemove = this.model.users.findIndex(x => x == user.id);
      if (indexToRemove >= 0)
        this.model.users.splice(indexToRemove, 1);
    }
  }

  addUser(user){
    if (!this.model.users)
      this.model.users = [];

    if (this.model.users.find(x => x == user.id) != undefined)
        this.toastService.error(this.translations.errors.user_already_selected);
    else {
        this.model.users.push(user.id);
    }
  }

  async created() {
    this.platformLanguages = await this.eventService.relatedLanguages(
      this.$route.params.idEvent
    );
    this.categories = await this.categoryService.list();
  }
}
</script>
